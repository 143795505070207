import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Snatch Grip High Pulls 4×4`}</p>
    <p>{`OHS 4×8\\@70% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`20-Power Snatch (75/55)(RX+ 95/65)(Scale %=50)`}</p>
    <p>{`10-Jump Squats`}</p>
    <p>{`15-Power Snatch (95/65)(RX+115/75)(Scale %=60%)`}</p>
    <p>{`20-Jump Squats`}</p>
    <p>{`10-Power Snatch (115/75)(RX+135/95)(Scale %=75)`}</p>
    <p>{`30-Jump Squats`}</p>
    <p>{`5-Power Snatch (135/95)(RX+ 155/105)(Scale %=85)`}</p>
    <p>{`for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      